import { getAppName, getAppVersion } from './utilities';
import { generalTracking, trackingTypes } from './types';
import * as StackTrace from 'stacktrace-js';
import Cookies from 'js-cookie';

const pkg = require('../package.json');

const stacktrace = StackTrace.getSync();
const appName = getAppName(stacktrace);
const appVersion = getAppVersion(stacktrace);

const enrich = async (data: generalTracking) => {
  const payload = data;

  // const stack = await StackTrace.get();
  // payload.stack = stacktrace;
  payload.appName = appName;
  payload.appVersion = appVersion;
  payload.userSession = Cookies.get('_visitor');
  payload.formatVersion = pkg.version;

  if (!payload.type) payload.type = [trackingTypes.behaviour];

  return payload;
};

export default enrich;
