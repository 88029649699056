import { StackFrame } from 'stacktrace-js';

const getAppName = (stack: StackFrame[]) => {
  // ex. https://450.mit.webplatform-hybridapps-staging-01.topdanmark.cloud/hybridapps/c9-stakeholderview-selector/latest/static/js/index.js

  if (!stack) return 'undefined';
  if (!stack[0]) return 'undefined';
  if (!stack[0].fileName) return 'undefined';

  const parts = stack[0].fileName.split('/');

  const index = parts.findIndex((item: string) => item === 'hybridapps');
  if (index === -1) return 'undefined';

  const appName = parts[index + 1];
  return appName;
};

const getAppVersion = (stack: StackFrame[]) => {
  // ex. https://450.mit.webplatform-hybridapps-staging-01.topdanmark.cloud/hybridapps/c9-stakeholderview-selector/latest/static/js/index.js

  if (!stack) return 'undefined';
  if (!stack[0]) return 'undefined';
  if (!stack[0].fileName) return 'undefined';

  const parts = stack[0].fileName.split('/');
  const index = parts.findIndex((item: string) => item === 'hybridapps');
  if (index === -1) return 'undefined';

  const appVersion = parts[index + 2];
  return appVersion;
};

export { getAppName, getAppVersion };
