import { StackFrame } from 'stacktrace-js';

declare type item = {
  key: string;
  value: string | number | boolean | product | product[] | formfield | formfield[];
};

declare type sum = {
  name: string;
  value: string | number;
};

declare type coverage = {
  name: string;
  id?: string;
  value?: string | number;
};

enum criteriaKeys {
  milage = 'milage',
  destination = 'destination',
  mostExpensiveItemPrice = 'mostExpensiveItemPrice',
  specialContentPrice = 'specialContentPrice',
}
declare type criteria = {
  name: string | criteriaKeys;
  value: string | number | travelDestination;
};

enum consumers {
  console = 'console',
  ga = 'ga',
  sas360 = 'sas360',
  kibana = 'kibana',
  fb = 'fb',
}

declare type consumerOverwriteType = {
  consumers: consumers[];
};

enum trackingTypes {
  behaviour = 'behaviour',
  technical = 'technical',
  ecommerce = 'ecommerce',
}

// is the error technical or caused by the user (ex. form validation)
enum errorTypes {
  user = 'user',
  tech = 'tech',
  rule = 'rule',
}

declare type generalTracking = {
  stack?: StackFrame[];
  consumerOverwrite?: consumerOverwriteType;
  appName?: string;
  appVersion?: string;
  userSession?: string;
  formatVersion?: string;
  action: string;
  type: trackingTypes[];
  items: item[] | string;
  label?: string;
};

// ecommerce
enum travelDestination {
  europe = 'Europe',
  world = 'World',
}

enum priceDescr {
  once = 'oneTimePayment',
  monthly = 'monthly',
  yearly = 'yearly',
}

enum productGroup {
  insurance = 'forsikring',
}

enum actionCart {
  // view = 'cartview',
  checkout = 'checkout',
  purchase = 'purchase',
}

enum actionProduct {
  add = 'add',
  update = 'update',
  delete = 'delete',
}

declare type cartProductAction = {
  cartName?: string;
  action: actionProduct;
  productId: string;
  productName: string;
  /** english notation -  */
  unitPrice: number;
  quantity: number;
  group: productGroup;
};

/**
 * The `product` represents an insurance or pension product. Example:
 * ```typescript
 *  const product = {
 *     name: 'ulykkesforsikring',
 *     id: 'ulykkesforsikring007',
 *     group: 'forsikring',
 *     price: [1200, 2000],
 *     excess: 1000,
 *     coverages: [
 *         { name: 'death', value: 2000000 },
 *         { name: 'disabledSum', value: 1000000 }
 *      ],
 *     coverageSums: [
 *         { name: 'death yearly', value: 10000 },
 *         { name: 'invalidity monthly', value: 20000 },
 *         { name: 'death once', value: 30000 },
 *         { name: 'funeral services once', value: 50000 }
 *     ],
 *     criteria: [
 *         { name: 'milage', value: 20000 }
 *         { name: 'destination', value: travelDestination.europe }
 *         { name: 'specialContentPrice', value: 75000 }
 *         { name: 'other', value: 'something else' }
 *     ]
 * };
 * ```
 */
declare type product = {
  /** name of product */
  name: string;

  /** id of product */
  id: string;

  /** grouping, needed by SAS tracking. Currently only "forsikring" is allowed */
  group: productGroup;

  /** category, can be "housing", "health" or other */
  category?: string;

  /** selvrisiko */
  excess?: number;

  /** if the product is displayed with multiple pricing offerings, list prices here. SAS has a max of 3 */
  price?: number[] | number;
  priceDescr?: priceDescr;

  /** list the sums of the coverages -ex disabled-sum, death-sum or indbo sums. SAS has a max of 4 */
  coverageSums?: sum[];

  /** list selected coverages and other criteria, ex. risky sports, tooth, travel-destination, car milage etc. SAS has a max of 6 */
  coverages?: coverage[];

  criteria?: criteria[];
};

declare type cartAction = {
  action: actionCart;
  apiEventKey: string; // apiPurchaseView | apiCheckoutView

  /** max length 42 chars */
  id: number | string;
};

declare type formfield = {
  id: string;
  value: string;
  name?: string;
  alt?: string;
  obscure?: boolean;
  changes?: number;
};

export {
  criteria,
  criteriaKeys,
  product,
  travelDestination,
  productGroup,
  cartProductAction,
  actionProduct,
  cartAction,
  priceDescr,
  generalTracking,
  consumerOverwriteType,
  consumers,
  coverage,
  sum,
  item,
  trackingTypes,
  errorTypes,
  formfield,
};
