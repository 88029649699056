import { createContext } from 'react';
// @ts-ignore
import { LogLevels } from 'c9-js-log-client/dist/log-level-enum';

export interface ISiteConfig {
    environment: string;
    env: string;
    preview: boolean;
    logLevel: LogLevels | undefined;
    originalHost: string;
    sisterHost: string;
}

export interface IEndpointConfig {
    wso2host: string;
    apiAwsBase: string;
    apiAwsHost: string;
    apiGatewayBase: string;
    logger: string;
    jwtRefresh: string;
    cdnBase: string;
    apiHippoHost: string;
    contentDocuments: string;
    htmlContent: string;
    resourceBundle: string;
}

export interface IConfigProps {
    /** siteConfig fetched from topContext */
    siteConfig: ISiteConfig;
    /** endPointConfig fetched from topContext */
    endpointConfig: IEndpointConfig;
    /** appName */
    appName: string;
    /** Loglevel fetched from topContext */
    logLevel: LogLevels | undefined;
    /** Seconds to start the counter */
    counterStart?: number;
    /** hybridAppEventBus fetched from topContext */
    hybridAppEventBus: any;
}

const ConfigContext = createContext<IConfigProps>({} as IConfigProps);

export { ConfigContext };
