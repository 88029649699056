import { generalTracking } from './types';

export const validate = (data: generalTracking) => {
  if (!data.appName) throw Error('invalid data tracking data: missing "app name"');
  if (!data.action) throw Error('invalid data tracking data: missing "data action"');
  if (!data.type) throw Error('invalid data tracking data: missing "data type"');
  if (typeof data.items !== 'undefined') {
    if (Array.isArray(data.items)) {
      data.items.forEach((item) => {
        if (Object.keys(item).length !== 2)
          throw Error('invalid data tracking data: missing "data items must be a list of key-value pairs"');
      });
    } else throw Error('invalid data tracking data: missing "data items"');
  }

  return true;
};

const dispatchTrackingEvent = (data: generalTracking) => {
  if (Object.keys(data).length > 0) {
    // validate
    validate(data);

    // dispatch event
    if ((window as any).topContext) {
      (window as any).topContext.hybridAppEventBus.publish(data.type.join(','), data);
    } else {
      console.log('c9-tracking event missing event bus', data);
    }
  }
};

export default dispatchTrackingEvent;
