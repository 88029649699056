export const createAutoLogoutRef = (originalhost: string, sisterHost: string) => {
    if (originalhost.toLocaleLowerCase().includes('maegler')) {
        return `?ref=${encodeURIComponent('https://' + sisterHost.replace(/mit/, 'www') + '/auto-log-ud-maegler')}`;
    } else if (sisterHost.toLocaleLowerCase().includes('mit')) {
        return `?ref=${encodeURIComponent('https://' + sisterHost.replace(/mit/, 'www') + '/auto-log-ud')}`;
    } else {
        return `?ref=${encodeURIComponent('https://' + sisterHost + '/auto-log-ud')}`;
    }
};

export default createAutoLogoutRef;
