import { AxiosError } from 'axios';

/**
 * Utility type to make a read-only interface/type writable while internal processing is happening.
 *
 * @internal
 */
type Writeable<T> = {
    -readonly [P in keyof T]: T[P];
};

/**
 * Configuration object for the scaffold method
 */
export type ScaffoldConfig = {
    /**
     * Force the log entry to be made irregardless of the log level that the log instance was instantiated with
     */
    readonly force?: boolean;
};

/**
 * Log levels as an enum.
 */
export enum LogLevels {
    /**
     * error log level
     */
    error = 'error',
    /**
     * warn log level
     */
    warn = 'warn',
    /**
     * info log level
     */
    info = 'info',
    /**
     * debug log level
     */
    debug = 'debug'
}

/**
 * Pleace holder for errors which can't be mapped to Axios or Error
 */
export type CustomError = Readonly<{
    /**
     * The error message
     */
    message?: string;
    /**
     * The error status code (mostly when the error was from a HTTP API call)
     */
    statusCode?: number | string;
    /**
     * The request url where the error happened (for HTTP API calls)
     */
    requestUrl?: string;
}>;

/**
 * A union type representing client error constructs that can be sent as an argument to the {@linkcode C9Logger.logError} method.
 * Currently supports Axios error responses, standard Error objects and a custom error object with specified properties.
 */
export type ClientError = AxiosError | Error | CustomError;

/**
 * This interface defines additional metadata that can be included when making a log statement.
 */
export interface LogEntryMetadata {
    /**
     *  A request id that the this log entry should be associated with/referenced to
     */
    readonly associatedRequestId?: string;
    /**
     *  The http status code of the error (i.e. 4xx, 5xx)
     */
    readonly errorStatusCode?: number | string;

    /**
     *  An error metadata message corresponding to the main log entry being made
     */
    readonly errorMessage?: string;
    /**
     *  The URL of a failing resource request (for API requests)
     */
    readonly errorRequestUrl?: string;
    /**
     * The stack trace of an error that occured
     */
    readonly errorStack?: string;
    /**
     * Custom data field for arbitrary data (does not accept objects)
     */
    readonly customField1?: string | number;
    /**
     * Custom data field for arbitrary data (does not accept objects)
     */
    readonly customField2?: string | number;
    /**
     * Custom data field for arbitrary data (does not accept objects)
     */
    readonly customField3?: string | number;
}

/**
 * The log event that will be sent to c9-js-log-api.
 */
export type C9LogClientEvent = {
    /**
     * The message to be logged
     */
    readonly message: Record<string, unknown> | Array<unknown> | string;
    /**
     * The category (or subsegment) that specifies a logical subsection within the calling application's context
     */
    readonly category: string;
    /**
     * The executionId is a unique value generated per instance of the c9-js-log-client. It can be used to filter out all logs made by a consumer frontend application in a given page session (from page load to page reload/redirect)
     */
    readonly executionId?: string;
    /**
     * The web log client version is a troubleshooting attribute that helps package maintainers get an overview of which version of `c9-js-log-client` is creating the logs seen in Kibana.
     *
     * The consumer does not have to specify this value as it will be baked into every outgoing call from this module.
     */
    readonly webLogClientVersion?: string;
    /**
     * The version of the application that created the logger instance.
     */
    readonly appVersion?: string;
    /**
     * The name of the application that is using this module to create log events
     */
    readonly appName: string;
    /**
     * The log level
     */
    readonly logLevel: LogLevels;
    /**
     * The type of log event this is.
     *
     * The type will be used by c9-js-log-api to determine what sort of log statement to make.
     *
     * - `basic`: Creates a [standard log entry](https://github.devops.topdanmark.cloud/c9/c9-js-logger#library-methods) in Kibana
     * - `entities`: Creates an [entities type log entry](https://github.devops.topdanmark.cloud/c9/c9-js-logger/blob/master/documentation/2_utilityMethods.md#entities) in Kibana
     */
    readonly type: 'basic' | 'entities';
    /**
     * The key to be associated to an object being logged via the {@linkcode C9Logger.entities} method.
     */
    readonly key?: string;
    /**
     * The browser url where the log client is running on and where the log entry was made.
     */
    readonly viewerUrl?: string;
    /**
     *  Log metadata - additional information associated to the log statement being made.
     */
    readonly logEntryMetadata?: LogEntryMetadata;
};

/**
 * Creates a new log instance to be used in front-end applications.
 *
 * The {@linkcode C9Logger.logEndpoint} can be obtained from `TopContext` using `topContext.config.getEndpointConfig().logger`.
 *
 * The {@linkcode C9Logger.logLevel} can be obtained from `TopContext` using `topContext.config.getSiteConfig().logLevel`.
 *
 * @example Create a new log instance that logs to the console:
 *
 * ```ts
 * import { C9Logger, LogLevels } from 'c9-js-log-client';
 *
 * const logger = new C9Logger('console', 'testApp', topContext.config.getSiteConfig().logLevel);
 * ```
 *
 * @example Create a new log instance with your own log level:
 *
 * ```ts
 * import { C9Logger, LogLevels } from 'c9-js-log-client';
 *
 * const logger = new C9Logger(topContext.config.getEndpointConfig().logger, 'myAppName', LogLevels.error);
 * ```
 * @example Create a new log instance along with the version of the application that is instantiating it:
 *
 * ```ts
 * import { C9Logger, LogLevels } from 'c9-js-log-client';
 * import { version } from '../package.json';
 *
 * const logger = new C9Logger('https://log.endpoint/log', 'myAppName', LogLevels.debug, version);
 * ```
 */
export class C9Logger {
    /**
     * Convenience field for internal class usage.
     * @internal
     */
    public static readonly LOG_LEVEL_KEYS = Object.keys(LogLevels);
    /**
     * The default configuration for log method scaffolding
     * @internal
     */
    public static readonly DEFAULT_SCAFFOLD_CONFIG: ScaffoldConfig = {
        force: false
    };
    /**
     * The API Gateway endpoint that exposes the /log resource of c9-js-log-api OR should be set to "console" if developing in localhost
     */
    private readonly _logEndpoint: string;
    /**
     * The application name that the logger was instantiated with.
     */
    private _appName: string;
    /**
     * The version of the application that created the logger instance.
     */
    private _appVersion: string | undefined;
    /**
     * Runtime copy of the scaffold config
     */
    private _scaffoldConfig?: ScaffoldConfig;
    /**
     * The log level that the logger instance was initialized with.
     */
    private readonly _logLevel: LogLevels;
    /**
     * A unique id generated "per instance" of the {@linkcode C9Logger}.
     */
    private readonly _executionId: string;

    /**
     * Create a new instance of the C9Logger.
     *
     * @param logEndpoint The log api that will accepts log events for processing
     * @param appName The front end application making the logs
     * @param logLevel The log level of the log instance
     * @param appVersion An optional app version that
     */
    constructor(logEndpoint: string, appName: string, logLevel: LogLevels = LogLevels.info, appVersion?: string) {
        this._executionId = Math.random().toString(36).substr(2, 16);
        this._logEndpoint = logEndpoint;
        this._appName = appName;
        this._logLevel = logLevel;
        this._appVersion = appVersion;
    }

    /**
     * Gets the application name configured for this logger instance.
     */
    public get appName(): string {
        return this._appName;
    }

    /**
     * Sets the application name for the logger instance.
     *
     * @param value The new application name to set.
     */
    public set appName(value: string) {
        this._appName = value;
    }

    /**
     * Retrieves the application name set to the log instance during initialization
     *
     * @deprecated This method has been deprecated since version 5.0.0. Use the {@linkcode C9Logger.appName} getter method to get the application name instead.
     *
     * @returns The application name that has been set.
     */
    public getApplicationName(): string {
        return this.appName;
    }

    /**
     * Overrides the application name set to the log instance during initialization
     *
     * @deprecated This method has been deprecated since version 5.0.0. Use the {@linkcode C9Logger.appName} setter method to set the application name instead.
     *
     *  @param appName The new application name to be used
     *
     * @returns A string containing the application name
     */
    public setApplicationName(appName: string): void {
        this.appName = appName;
    }

    /**
     * Returns the appVersion
     */
    public get appVersion(): string | undefined {
        return this._appVersion;
    }

    /**
     * A unique id generated "per instance" of the {@linkcode C9Logger}.
     * This value can be used to uniquely identify log events made by a given front end app on the page.
     *
     * @returns A unique id as a string
     */
    public get executionId(): string {
        return this._executionId;
    }

    /**
     * The configured log endpoint for this logger instance
     */
    public get logEndpoint(): string {
        return this._logEndpoint;
    }

    /**
     * The configured log level for this logger instance.
     */
    public get logLevel(): LogLevels {
        return this._logLevel;
    }

    /**
     * A scaffolding method to set configurations before log messages are made.
     *
     * The scaffolding configuration is a <b>ONE TIME</b> configuration. Once a logging method is invoked after the scaffold, the configuration will be
     * reset back to default.
     *
     * @example
     * ```ts
     *
     * import { C9Logger, LogLevels } from 'c9-js-log-client';
     *
     * const logger = new C9Logger('https://log.endpoint/log', 'myApp', LogLevels.error);
     *
     * // Based on the logger instance configuration, this message won't be logged because it is a debug message
     * logger.debug('Verbose message that wont be logged', 'verbose');
     *
     * // Use scaffold to override the log implementation and force the logger to log at debug level on a one time basis
     * logger.scaffold({ force: true }).debug('A verbose message', 'verbose');
     * ```
     *
     * @param config The scaffolding config to be applied when logging methods are invoked
     * @returns The current C9Logger instance
     */
    public scaffold(config: ScaffoldConfig): C9Logger {
        this._scaffoldConfig = config;
        return this;
    }

    /**
     * Dedicated method for logging errors in front end applications.
     *
     * If a compatible error object is passed to this method, the logic will attempt to extract relevant information
     * from it to be logged.
     *
     * @param message The message to be logged
     * @param category An optional category to supply create a logical separation of logs within a given application
     * @param error Front End App client error either `AxiosError`, `Error` or {@linkcode CustomError}
     * @param logEntryMetadata Metadata for the error being logged
     */
    public logError(
        message: string,
        category = '',
        error: ClientError,
        logEntryMetadata: LogEntryMetadata
    ): Promise<void> {
        const metadata: Writeable<LogEntryMetadata> = logEntryMetadata;
        if (error instanceof Error) {
            metadata.errorMessage = error.message;
            metadata.errorStack = error.stack;
        } else if (this.isAxiosError(error)) {
            metadata.errorMessage = error.response?.statusText;
            metadata.errorStatusCode = error.response?.status;
            metadata.errorRequestUrl = error.config.url;
            metadata.errorStack = error.stack;
        } else {
            metadata.errorMessage = error.message;
            metadata.errorStatusCode = error.statusCode;
            metadata.errorRequestUrl = error.requestUrl;
        }
        return this.error(message, category, metadata);
    }

    /**
     * Publish a log message at error level.
     *
     * @param message The message to be logged
     * @param category An optional category to supply create a logical separation of logs within a given application
     * @param logEntryMetadata Metadata log entity to capture additional attributes
     *
     */
    public error(message: string, category?: string, logEntryMetadata?: LogEntryMetadata): Promise<void> {
        return this.createLogEntry(message, LogLevels.error, category, logEntryMetadata);
    }

    /**
     * Publish a log message at warn level.
     *
     * @param message The message to be logged
     * @param category An optional category to supply create a logical separation of logs within a given application
     * @param logEntryMetadata Metadata log entity to capture additional attributes
     *
     */
    public warn(message: string, category?: string, logEntryMetadata?: LogEntryMetadata): Promise<void> {
        return this.createLogEntry(message, LogLevels.warn, category, logEntryMetadata);
    }

    /**
     * Publish a log message at info level.
     *
     * @param message The message to be logged
     * @param category An optional category to supply create a logical separation of logs within a given application
     * @param logEntryMetadata Metadata log entity to capture additional attributes
     *
     */
    public info(message: string, category?: string, logEntryMetadata?: LogEntryMetadata): Promise<void> {
        return this.createLogEntry(message, LogLevels.info, category, logEntryMetadata);
    }

    /**
     * Publish a log message at debug level.
     *
     * @param message The message to be logged
     * @param category An optional category to supply create a logical separation of logs within a given application
     * @param logEntryMetadata Metadata log entity to capture additional attributes
     *
     */
    public debug(message: string, category?: string, logEntryMetadata?: LogEntryMetadata): Promise<void> {
        return this.createLogEntry(message, LogLevels.debug, category, logEntryMetadata);
    }

    /**
     * Publish a log message containing an array or object tagged by a key at the specified log level.
     *
     * NOTE: This method does not support log entry metadata as it is already logging an object.
     *
     * @param level The log level to use for logging
     * @param key The key for the object to be logged
     * @param message The object or array to be logged
     * @param category An optional category to supply create a logical separation of logs within a given application
     *
     */
    public entities(
        level: LogLevels,
        key: string,
        message: Record<string, unknown> | Array<unknown>,
        category?: string
    ): Promise<void> {
        if (arguments.length < 3) {
            throw Error('invoking C9Logger.entities requires a log level, key and object');
        }

        return this.createLogEntry(message, level, category, undefined, 'entities', key);
    }

    /**
     * Function that publishes a log event to the log endpoint.
     *
     * @internal
     *
     * @param message The message to be logged
     * @param logLevel The log level that should be used
     * @param category The category (arbitrary subsegment of the application) to associate this log entry with
     * @param logEntryMetadata Metadata log entity to capture additional attributes from front end apps
     * @param type The type of log entry to create
     * @param key The key for the object to be logged (only for entities)
     */
    private createLogEntry(
        message: C9LogClientEvent['message'],
        logLevel: LogLevels,
        category = '',
        logEntryMetadata?: LogEntryMetadata,
        type: C9LogClientEvent['type'] = 'basic',
        key?: string
    ): Promise<void> {
        let response: Promise<void>;

        // Log only when log level is allowed OR if scaffold configuration specifies to force log no matter what the method is
        if (this._scaffoldConfig?.force || this.compareLogLevels(logLevel, this.logLevel)) {
            response = this.callServer(this.logEndpoint, {
                message,
                logLevel,
                appName: this.appName,
                category,
                executionId: this.executionId,
                type,
                key,
                logEntryMetadata
            });
        } else {
            response = Promise.resolve();
        }

        // Reset the scaffold config after the log event has been made
        this._scaffoldConfig = {
            ...(this._scaffoldConfig ?? C9Logger.DEFAULT_SCAFFOLD_CONFIG),
            force: false
        };

        return response;
    }

    /**
     * Compares the first level against the second log level and returns true if the first level is less than or equal to the second log level.
     *
     * @internal
     *
     * @param firstLevel
     * @param secondLevel
     * @returns
     */
    private compareLogLevels(firstLevel: LogLevels, secondLevel: LogLevels): boolean {
        const indexOfFirstLevel: number = C9Logger.LOG_LEVEL_KEYS.indexOf(firstLevel);
        const indexOfSecondLevel: number = C9Logger.LOG_LEVEL_KEYS.indexOf(secondLevel);
        return indexOfFirstLevel <= indexOfSecondLevel;
    }

    /**
     * Method to distinguish AxiosError from {@linkcode ClientError} union type.
     *
     * @internal
     *
     * @description While Axios already has this method, we define it here again because we don't want to package Axios as a dependency of this module.
     *
     * @param error 'ClientError' union type 'AxiosError' ,'Error' or 'ClientError'
     * @returns
     */
    private isAxiosError(error: unknown): error is AxiosError {
        return typeof (error as AxiosError)?.isAxiosError === 'boolean';
    }

    /**
     * Invoke the log lambda endpoint to transfer the log message to the log ingestion system.
     *
     * For all calls, the viewer URL (current browser URL) is added to the log entry made.
     *
     * @internal
     *
     * @param logEndpoint The service URL to post the log event to
     * @param logEvent The actual log event object to be posted
     * @returns
     */
    private async callServer(logEndpoint: string, logEvent: C9LogClientEvent): Promise<void> {
        if (logEndpoint === 'console') {
            console.log(logEvent.message);
            return Promise.resolve();
        }

        return fetch(logEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...logEvent,
                webLogClientVersion: process.env.MODULE_NAME,
                viewerUrl: window.location.href,
                appVersion: this._appVersion
            })
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }
                return response.text();
            })
            .then(console.log)
            .catch((error) => console.error('Problem with fetch operation', error.message));
    }
}

/**
 * Factory method for logger creation.
 *
 * Refer to {@linkcode C9Logger} for more information about the arguments expected.
 *
 * Note: You can also create a new {@linkcode C9Logger} instance using `new C9Logger(...)`
 *
 * @example Create instance with default log level (info)
 * ```ts
 * import logFactory from 'c9-js-log-client';
 *
 * const logger = logFactory('logEndpoint', 'myAppName');
 * ```
 *
 * @example Create instance with custom log level
 * ```ts
 * import logFactory, { LogLevels } from 'c9-js-log-client';
 *
 * const logger = logFactory('console', 'myAppName', 'debug' as unknown as LogLevels); // cast your custom log level to unknown as then cast it again to LogLevels
 * ```
 *
 * @param {C9LoggerArgs} args The constructor arguments required by {@linkcode C9Logger}'s constructor
 * @returns
 */
const logFactory = (...args: ConstructorParameters<typeof C9Logger>): C9Logger => {
    const [logEndpoint, appName, logLevel, ...rest] = args;
    // Runtime validation for logLevel in case it is passed in a format that isn't compliant
    const maybeLogLevel = (logLevel as string)?.toLowerCase();
    const finalLogLevel = Object.keys(LogLevels).includes(maybeLogLevel) ? (maybeLogLevel as LogLevels) : undefined;
    return new C9Logger(logEndpoint, appName, finalLogLevel, ...rest);
};

export default logFactory;
