import { product, trackingTypes } from './types';
import dispatchProxy from './dispatchProxy';

/**
 * Do tracking of ecommerce related events. You can notify systems like Google/Facebook Ecommerce and SAS ecommerce for better reporting, remarketing etc.
 * Documentation can be found here: [SAS](https://go.documentation.sas.com/api/collections/cintcdc/production.a/docsets/cintag/content/cintag.pdf?locale=en), [Google](https://topdanmark.sharepoint.com/:b:/s/Cloud9-O365/EQIz5IedmPJFjPYHHxmDwXcBrGwA48SOE4J7DGiurepPRg?e=g3egd2) and [Facebook](https://developers.facebook.com/docs/facebook-pixel/reference/#standard-events)
 */
class Ecommerce {
  /**
   * Inform SAS, GA and Kibana that a product was displayed. Call this every time product is displayed with different settings
   * ```typescript
   * import {ecommerce} from 'c9-tracking';
   * ...
   * const product = {
   *     name: 'ulykkesforsikring',
   *     id: 'ulykkesforsikring007',
   *     group: 'forsikring',
   * };
   *  ecommerce.viewProduct(product);
   * ```
   * @returns {Promise<NULL|string>}
   * @param product product type (car, accident, content or travel)
   */
  viewProduct(prod: product) {
    return dispatchProxy({
      type: [trackingTypes.ecommerce],
      action: 'app-ecommerce-view',
      items: [{ key: 'product', value: prod }],
    });
  }

  cart = {
    /**
     * add product to cart
     * ```
     * import {ecommerce} from 'c9-tracking';
     * ...
     * const product = {
     *     name: 'ulykkesforsikring',
     *     id: 'ulykkesforsikring007',
     *     group: 'forsikring',
     * };
     * ecommerce.cart.add(product)
     * ```
     *
     * @returns {Promise<NULL|string>}
     * @param {product} product to add
     */
    add(prod: product) {
      return dispatchProxy({
        type: [trackingTypes.ecommerce],
        action: 'app-ecommerce-cart',
        items: [
          { key: 'cartAction', value: 'add' },
          { key: 'product', value: prod },
        ],
      });
    },

    /**
     * remove the given product
     * ```
     * import {ecommerce} from 'c9-tracking';
     * ...
     * const product = {
     *     name: 'ulykkesforsikring',
     *     id: 'ulykkesforsikring007',
     *     group: 'forsikring',
     * };
     * ecommerce.cart.remove(product)
     * ```
     * @returns {Promise<NULL|string>}
     * @param {product} product to remove
     */
    remove(prod: product) {
      return dispatchProxy({
        type: [trackingTypes.ecommerce],
        action: 'app-ecommerce-cart',
        items: [
          { key: 'cartAction', value: 'remove' },
          { key: 'product', value: prod },
        ],
      });
    },
  };

  /**
   * Describe what products are available at which step in the checkout proces
   * ```
   * import {ecommerce} from 'c9-tracking';
   * ...
   * ecommerce.checkout(5, [product1, product2, product3])
   * ```
   * @returns {Promise<NULL|string>}
   * @param stepNumber
   * @param products
   */
  checkout(stepNumber: number, products: product[]) {
    return dispatchProxy({
      type: [trackingTypes.ecommerce],
      action: 'app-ecommerce-checkout',
      items: [
        { key: 'step', value: stepNumber },
        { key: 'products', value: products },
      ],
    });
  }

  /**
   * Emit final "purchase" event
   * ```
   * import {ecommerce} from 'c9-tracking';
   * ...
   * ecommerce.purchase([product1, product2, product3], 'az123')
   * ```
   * @returns {Promise<NULL|string>}
   * @param purchaseInfo
   * @param products
   * @param orderId
   */
  purchase(products: product[], orderId?: string | number) {
    return dispatchProxy({
      type: [trackingTypes.ecommerce],
      action: 'app-ecommerce-purchase',
      items: [
        { key: 'products', value: products },
        { key: 'orderId', value: orderId ? orderId : '' },
      ],
    });
  }
}

export default new Ecommerce();
