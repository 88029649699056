import { item, trackingTypes, errorTypes, formfield, consumerOverwriteType } from './types';
import dispatchProxy from './dispatchProxy';

class Track {
  /**
   * Inform SAS, GA and Kibana that your application is loaded and weather it is available to the user or not.
   * ```typescript
   * import {track} from 'c9-tracking';
   * ...
   * track.ready(true);
   * ```
   * @returns {Promise<NULL|string>}
   * @param available set to false if the application is hidden or disabled
   * @param label describe the availability with a human understandable short description
   * @param consumerOverwrite overwrites to witch consumers is tracked, if none provide it will track all consumers.
   */
  ready(available = true, label?: string, consumerOverwrite?: consumerOverwriteType) {
    return dispatchProxy({
      ...consumerOverwrite,
      type: [trackingTypes.behaviour],
      action: 'app-ready',
      items: [{ key: 'available', value: available }],
      label,
    });
  }

  /**
   * Tell SAS360, GA and Kibana that a conversion happened.
   * It could be an email signup, sending a message or buying a product
   * ```typescript
   * import {track} from 'c9-tracking';
   * ...
   * track.convert([
   *    { key: 'email', value: 'signup' },
   *    { key: 'list', value: 'new-list-123' }
   * ]);
   * track.convert('User bought an insurance');
   * ```
   * @returns {Promise<NULL|string>}
   * @param items
   * @param label describe the conversion with a human understandable short description
   * @param consumerOverwrite overwrites to witch consumers is tracked, if none provide it will track all consumers.
   */
  convert(items: item[] | string, label?: string, consumerOverwrite?: consumerOverwriteType) {
    if (typeof items === 'string') items = [{ key: 'message', value: items }];
    return dispatchProxy({
      ...consumerOverwrite,
      type: [trackingTypes.behaviour],
      action: 'app-convert',
      items,
      label,
    });
  }

  /**
   * Inform SAS, GA and Kibana about where in a multistep flow the customer are. The aim is to visualyze a funnel
   * ```typescript
   * import {track} from 'c9-tracking';
   * ...
   * track.step(2, 5);  // use numbered steps ... or
   * track.step('Supply user details', 5); // use named steps
   * ```
   * @returns {Promise<NULL|string>}
   * @param current current step - name or number
   * @param total number of total steps
   * @param label describe the funnel with a human understandable short description
   * @param consumerOverwrite overwrites to witch consumers is tracked, if none provide it will track all consumers.
   */
  step(current: number | string, total: number, label?: string, consumerOverwrite?: consumerOverwriteType) {
    return dispatchProxy({
      ...consumerOverwrite,
      type: [trackingTypes.behaviour],
      action: 'app-step',
      items: [
        { key: 'current', value: current },
        { key: 'total', value: total },
      ],
      label,
    });
  }

  /**
   * Tell SAS360, GA and Kibana that an interaction occoured with your application.
   * Ex. open FAQ element, typing in a field...
   * ```typescript
   * import {track} from 'c9-tracking';
   * ...
   * track.interact([
   *   { key: 'faq', value: 'open' },
   *   { key: 'faq-id', value: '123' }
   * ], {
   *     consumerOverwrite: [ga, kibana]   *
   *    }
   * );
   * track.interact('log out');  // or simple string
   * ```
   * @returns {Promise<NULL|string>}
   * @param items
   * @param label describe the interaction with a human understandable short description
   * @param consumerOverwrite overwrites to witch consumers is tracked, if none provide it will track all consumers.
   */
  interact(items: item[] | string, label?: string, consumerOverwrite?: consumerOverwriteType) {
    if (typeof items === 'string') items = [{ key: 'message', value: items }];
    return dispatchProxy({
      ...consumerOverwrite,
      type: [trackingTypes.behaviour],
      action: 'app-interact',
      items,
      label,
    });
  }

  /**
   * Tell SAS360, GA and Kibana that the user navigated inside your SPA - aka virtual pageview
   * ```typescript
   * track.navigate(document.URL + 'get-quote', 'Show quote to customer');
   * ```
   * @returns {Promise<NULL|string>}
   * @param route full url + your virtual roucte
   * @param label
   * @param consumerOverwrite overwrites to witch consumers is tracked, if none provide it will track all consumers.
   */
  navigate(route: string, pageTitle?: string, label?: string, consumerOverwrite?: consumerOverwriteType) {
    const items = [{ key: 'route', value: route }];
    if (pageTitle) items.push({ key: 'pageTitle', value: pageTitle });
    return dispatchProxy({
      ...consumerOverwrite,
      type: [trackingTypes.behaviour],
      action: 'app-navigate',
      items,
      label,
    });
  }

  /**
   * Push additional information to SAS, GA and Kibana.
   * It could be calculations of intermediate prices or data fetched from other services
   * ```typescript
   * imprt {track} from 'c9-tracking';
   * ...
   * track.info([
   *    { key: 'annual-pay', value: '1230' },
   *    { key: 'monthly-pay', value: '123' }
   * ]);
   * track.info('bla bla bla');
   * ```
   * @returns {Promise<NULL|string>}
   * @param items
   * @param label describe the additional information with a human understandable short description
   * @param consumerOverwrite overwrites to witch consumers is tracked, if none provide it will track all consumers.
   */
  info(items: item[] | string, label?: string, consumerOverwrite?: consumerOverwriteType) {
    if (typeof items === 'string') items = [{ key: 'message', value: items }];
    return dispatchProxy({
      ...consumerOverwrite,
      type: [trackingTypes.behaviour],
      action: 'app-info',
      items,
      label,
    });
  }

  /**
   * Track an error. Remember this is NOT for debugging so dont enter technical info in the message
   * Ex. invalid form field value...
   * ```js
   * import {track} from 'c9-tracking';
   * import {errorTypes} from 'c9-tracking/lib/types';
   * ...
   * track.fault('email validation failed', errorTypes.user, 'Sign-up flow');
   * ```
   * @returns {Promise<NULL|string>}
   * @param message describe what is the issue
   * @param type is the error a technical error (ex. endpoint timeout) or a human error (ex. form validation failed)
   * @param label describe the interaction with a human understandable short description
   * @param technicalInfo add technical info here - if you must
   * @param consumerOverwrite overwrites to witch consumers is tracked, if none provide it will track all consumers.
   */
  fault(
    message: string,
    type: errorTypes,
    label?: string,
    technicalInfo?: string,
    consumerOverwrite?: consumerOverwriteType,
  ) {
    const items = [{ key: 'message', value: message }];
    if (technicalInfo) items.push({ key: 'technical', value: technicalInfo });
    return dispatchProxy({
      ...consumerOverwrite,
      type: [trackingTypes.behaviour],
      action: 'app-error-' + type,
      items,
      label,
    });
  }

  /**
   * Track a form submit.
   * Ex.
   * ```js
   * const field1 = { id: 'name', value: name };
   * const field2 = { id: 'age', value: age };
   * const field3 = { id: 'addr', value: addr };
   * track.form('form-id', [field1, field2, field3]);
   * ```
   * @param name
   * @param fields
   * @param id
   * @param attempts
   * @param consumerOverwrite overwrites to witch consumers is tracked, if none provide it will track all consumers.
   */
  form(
    id: string,
    fields: formfield[],
    name?: string,
    attempts?: number,
    label?: string,
    consumerOverwrite?: consumerOverwriteType,
  ) {
    const items: item[] = [
      { key: 'id', value: id },
      { key: 'fields', value: fields },
    ];
    if (name) items.push({ key: 'name', value: name });
    if (attempts) items.push({ key: 'attempts', value: attempts });
    if (label) items.push({ key: 'label', value: label });
    if (name) items.push({ key: 'name', value: name });

    return dispatchProxy({
      ...consumerOverwrite,
      type: [trackingTypes.behaviour],
      action: 'app-form',
      items,
      label,
    });
  }
}

export default new Track();
