import enrich from './enrich';
import dispatchTrackingEvent from './dispatchTrackingEvent';
import { generalTracking } from './types';

/**
 * @returns {Promise<NULL|string>}
 * @param {generalTracking} data
 */
const dispatchProxy = async (data: generalTracking): Promise<null | string> => {
  const payload = await enrich(data);
  try {
    dispatchTrackingEvent(payload);
    return null;
  } catch (error) {
    return (error as Error).message;
  }
};

export default dispatchProxy;
